function setRedirectCookie(path: string) {
  const cookie = useCookie('redirect', {
    maxAge: 300,
  })
  cookie.value = path
}

export default defineNuxtRouteMiddleware((_, from) => {
  const { status } = useAuth()

  if (status.value !== 'authenticated') {
    setRedirectCookie(from.fullPath)
    return navigateTo('/inloggen', { redirectCode: 302 })
  }
})
